import React from "react";

import { Container, Col, Row } from "react-bootstrap";
import bgImage from "../../assets/images/shapes/event-map-1-2.png";

import image1 from "../../assets/images/stock/AdobeStock_213925805_Preview.jpeg";
import image2 from "../../assets/images/stock/AdobeStock_374849081_Preview.jpeg";
import image3 from "../../assets/images/stock/AdobeStock_119098395_Preview.jpeg";
import image4 from "../../assets/images/stock/AdobeStock_422257359_Preview.jpeg";
import image5 from "../../assets/images/myltf/645328526_FullSizeR_856473.jpg"
import heart from "../../assets/images/shapes/Green-ball.png";
import aboutImage from "../../assets/images/myltf/IMG_9647.jpg";

const EVENT_HOME_TWO_DATA = [
  {
    image: image1,
    title: "Integrity",
    text: "Instill honesty, truth, sportsmanship and respect",
  },
  {
    image: image2,
    title: "Leadership",
    text: "Demonstrate responsibility, accountability, and commitment through actions",
  },
  {
    image: image3,
    title: "Inclusion",
    text: "Ensure all individuals have equal opportunities, access and diversity",
  },
  {
    image: image5,
    title: "Community",
    text: "Create social and cultural connections of friendships through community",
  },
  {
    image: image4,
    title: "Excellence",
    text: "Commit to continuous improvement driven by passion and the spirit of love",
  },
];

const MissionContent = () => {
  return (
    <>
      <Container>
        <Row className="pt-120 pb-60">
          <Col xl={6}>
            <div className="">
              <img src={aboutImage} className="img-fluid" alt="" />
            </div>
          </Col>
          <Col xl={6}>
            <div className="about-two__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" /> About MYLTF
                </p>
              </div>
              <Row>
                <div className="about-two__box">
                  <h3>
                    <i className="azino-icon-confirmation"></i> OUR MISSION:
                  </h3>
                  <p>
                    Made You Love Tennis's mission is to impact underserved
                    youth through holistic tennis, education, character
                    development, literacy, and scholarships so that all can
                    achieve academic and athletic excellence.
                  </p>
                </div>
                <div className="about-two__box">
                  <h3>
                    <i className="azino-icon-confirmation"></i> OUR VISION:
                  </h3>
                  <p>
                    Made You Love Tennis enriches the lives of youth and
                    community through tennis, education, and outreach.
                  </p>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <section
        className="event-home-two pt-60 pb-120"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <Container>
          <h3 className="pb-20 text-center">OUR VALUES</h3>
          <div className="event-grid pt-100 mission-card">
            {EVENT_HOME_TWO_DATA.map((event, index) => (
              <MissionCard data={event} key={`event-card-key-${index}`} />
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

const MissionCard = ({ data }) => {
  const { image, title, text } = data;

  return (
    <div className="event-card">
      <div className="event-card-inner">
        <div className="event-card-image">
          <div className="event-card-image-inner">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="event-card-content">
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default MissionContent;
